import clsx from 'clsx'
import Link from 'next/link'
import { CSSTransition } from 'react-transition-group'
import { useAcl } from '~/hooks/user'
import { useStore } from '~/stores'
import styles from './SideBar.module.scss'
import NavGroup, { type NavGroupType } from './sideBar/NavGroup'
import SvgIcon from '~/components/common/SvgIcon'

export default function SideBar() {
  const store = useStore()
  const acl = useAcl()

  const nav: NavGroupType[] = [
    // {
    //   label: 'Dashboard',
    //   link: '/dashboard',
    //   icon: 'home-line'
    // },
    {
      label: 'Projects',
      link: '/projects',
      icon: 'users-01'
    }
  ]

  const footer: NavGroupType[] = []

  if (acl.can('settings')) {
    footer.push({
      label: 'Settings',
      link: '/settings/company-profile',
      icon: 'settings-01'
    })
  }

  acl.isSuperAdmin &&
    footer.push({
      label: 'Manage Tenants',
      link: '/admin/tenants',
      icon: 'git-branch-01'
    })

  return (
    <>
      <div
        className={clsx(styles.sidebar, {
          'is-toggled': store.isSidebarToggled
        })}
      >
        <header className="header">
          <Link href="/dashboard">
            <a>
              <SvgIcon height={60} icon="kale" folder="svgs" className="color-primary" />
            </a>
          </Link>
        </header>

        <div className="scroll mx-16 flex-column">
          {nav.map((item, i) => (
            <NavGroup key={i} {...item} />
          ))}
        </div>

        <div className="mx-16 my-16">
          {footer.map((item, i) => (
            <NavGroup key={i} {...item} />
          ))}
        </div>
      </div>

      <CSSTransition
        addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
        in={store.isSidebarToggled}
        classNames="fade"
        unmountOnExit
      >
        <div className={styles.cover}>
          <button className="over-fill" onClick={() => store.setIsSidebarToggled(false)}></button>
        </div>
      </CSSTransition>
    </>
  )
}
